<template>
  <base-material-card
    icon="mdi-counter"
    title="Policies"
    color="secondary"
  >
    <template v-slot:card-actions>
      <v-btn
        color="primary"
        small
        @click="addPolicyDlg = true"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Add New
      </v-btn>
    </template>
    <v-row
      justify="space-between"
      align="center"
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="filterOptions.search"
          label="Search"
          append-icon="mdi-magnify"
          clearable
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-select
          v-model="filterOptions.policyStatusTypes"
          label="Status"
          :loading="loadingMixins.policyStatuses"
          :items="mixinItems.policyStatuses"
          item-text="name"
          item-value="id"
          multiple
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-checkbox
          v-model="filterOptions.potentialRenewal"
          label="Potential Renewal"
        />
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="loading"
      indeterminate
    />

    <v-data-table
      :headers="headers"
      :items="policies"
      :options.sync="options"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, i) in items"
            :key="i"
          >
            <td>
              <router-link
                :to="`/policies/${item.id}`"
              >
                {{ item.policy_number }}
              </router-link>
            </td>
            <td>
              <router-link
                :to="clientLink(item)"
              >
                {{ clientName(item) }}
              </router-link>
            </td>
            <td>{{ item.policy_type && item.policy_type.name }}</td>
            <td>{{ item.expiration_date }}</td>
            <td>{{ item.term && item.term.name }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <table-footer
      :options="options"
      :total="total"
    />

    <v-dialog
      v-model="addPolicyDlg"
      max-width="800"
    >
      <v-card>
        <v-card-title>Add New Policy</v-card-title>
        <v-card-text>
          <add-policy
            :from-client="fromClient"
            @close="addPolicyDlg = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </base-material-card>
</template>

<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'

  export default {
    components: {
      TableFooter: () => import('../components/TableFooter'),
      AddPolicy: () => import('./AddPolicy'),
    },

    mixins: [
      fetchInitials([
        MIXINS.policyStatuses,
      ]),
    ],

    props: {
      fromClient: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      headers: [
        { text: 'Policy Number', value: 'policy_number' },
        { text: 'Client', value: 'id', sortable: false },
        { text: 'Policy Type', value: 'policy_type' },
        { text: 'Expiration Date', value: 'expiration_date' },
        { text: 'Term', value: 'term' },
      ],
      policies: [],
      loading: false,
      total: 0,
      options: {},
      timeout: null,
      filterOptions: {},
      addPolicyDlg: false,
    }),

    watch: {
      options: {
        handler () {
          this.getPolicies()
        },
        deep: true,
      },
      filterOptions: {
        handler () {
          if (this.timeout) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(() => {
            this.options.page = 1
            this.getPolicies()
          }, 500)
        },
        deep: true,
      },
    },

    mounted () {
      // If from dashboard with potential renewal option
      if (this.$route.query.renewal === 'true') {
        this.filterOptions.potentialRenewal = true
      }
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async getPolicies () {
        this.loading = true
        try {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
          let apiurl = `policies/policies?page=${page}&per_page=${itemsPerPage}`
          if (this.filterOptions.search) {
            apiurl += `&query=${this.filterOptions.search.replace('&', '%26')}`
          }
          if (this.filterOptions.potentialRenewal) {
            apiurl += '&potential_renewal=true'
          }
          if (sortBy[0]) {
            const direction = sortDesc[0] ? 'desc' : 'asc'
            apiurl += `&direction=${direction}&sortBy=${sortBy[0]}`
          }
          if (this.fromClient) {
            const user = this.$route.params.id.split('-')[0]
            const id = this.$route.params.id.split('-')[1]
            apiurl += `&user=${user}&id=${id}`
          }
          const response = await axios.get(apiurl)
          this.policies = response.data.data
          this.total = response.data.total
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }
        this.loading = false
      },

      clientName (policy) {
        return (policy.insured_user_id !== null) ? policy.insured_user.first_name + ' ' + policy.insured_user.last_name : policy.insured_company.name
      },

      clientLink (policy) {
        const user = policy.insured_user_id ? 1 : 0
        const id = policy.insured_user_id || policy.insured_company_id
        return `/clients/${user}-${id}`
      },
    },
  }
</script>
